var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "地址校验结果",
        visible: _vm.dialogVisible,
        width: "560px",
        "show-close": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editData.classification === 0,
                expression: "editData.classification === 0",
              },
            ],
            attrs: {
              src: require("@/assets/images/seting/shangye.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editData.classification === 2,
                expression: "editData.classification === 2",
              },
            ],
            attrs: {
              src: require("@/assets/images/seting/zhuzhai.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editData.classification === 3,
                expression: "editData.classification === 3",
              },
            ],
            attrs: {
              src: require("@/assets/images/seting/weizhi.png"),
              alt: "",
            },
          }),
          _c(
            "div",
            {
              staticClass: "title",
              style:
                _vm.editData.typeName === 3 ? "color:#FEAA4F" : "color:#2395F3",
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: { disabled: "" },
            model: {
              value: _vm.address,
              callback: function ($$v) {
                _vm.address = $$v
              },
              expression: "address",
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }