<template>
  <el-dialog
    v-drag
    title="地址校验结果"
    :visible.sync="dialogVisible"
    width="560px"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="box">
      <img
        v-show="editData.classification === 0"
        src="@/assets/images/seting/shangye.png"
        alt=""
      />
      <img
        v-show="editData.classification === 2"
        src="@/assets/images/seting/zhuzhai.png"
        alt=""
      />
      <img
        v-show="editData.classification === 3"
        src="@/assets/images/seting/weizhi.png"
        alt=""
      />
      <div
        class="title"
        :style="editData.typeName === 3 ? 'color:#FEAA4F' : 'color:#2395F3'"
      >
        {{ title }}
      </div>
      <el-input v-model="address" disabled style="width: 400px;"></el-input>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      address: '334 W angela dr,Ontario,CA,85023',
      title: '商业地址'
    }
  },
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.code = ''
      }
    },
    editData(val) {
      if (val.classification === 0) {
        this.title = '商业地址'
      } else if (val.classification === 2) {
        this.title = '住宅地址'
      } else if (val.classification === 3) {
        this.title = '未知'
      }
    }
  },
  methods: {
    cancel() {
      // console.log(this.editData)
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    }
  }
}
</script>
<style lang="less" scoped>
/dee/ .el-dialog__header {
  text-align: left;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #f2f7ff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dbe4f2;
    color: #7c8db5;
    margin-right: 16px;
    box-sizing: border-box;
  }
  .ok {
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
  }
}
.title {
  margin-top: 30px;
  margin-block: 16px;
}
/deep/ .el-input__inner {
  width: 400px;
  height: 40px;
  background: #f8fbff;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: none;
  text-align: center;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
